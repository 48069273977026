import {
  Box,
  Container,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import InstagramIcon from './icons/Instagram';
import Logo from './icons/Logo';
import TwitterIcon from './icons/Twitter';
import WhatsappIcon from './icons/Whatsapp';

export default function Footer() {
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark';
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      w='100%'
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 2, md: 4 }}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Box alignContent='center'>
          <Logo />
        </Box>
        <Text>© 2021 KL 01 SPORTS. All rights reserved</Text>
        <Stack direction={'row'} spacing={6}>
          <TwitterIcon />
          <InstagramIcon />
          <WhatsappIcon />
        </Stack>
      </Container>
    </Box>
  );
}
