import {
  Container,
  Heading,
  Image,
  Stack,
  useColorMode,
} from '@chakra-ui/react';
import React from 'react';

const Logo = () => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark';

  return (
    <Container as={Stack} direction='row' spacing={1}>
      <Image
        src='/logov1.png'
        // ml={{ base: 4, md: 8 }}
        w='50px'
        h='50px'
        alignSelf={'center'}
        onClick={() => (window.location.href = '/')}
        _hover={{
          cursor: 'pointer',
        }}
      />
      <Heading
        // ml={{ base: 4, md: 8 }}
        size='md'
        fontWeight='bold'
        color={isDark ? 'white' : 'black'}
        alignSelf={'center'}
        onClick={() => (window.location.href = '/')}
        _hover={{
          cursor: 'pointer',
        }}
      >
        KL 01 SPORTS
      </Heading>
    </Container>
  );
};

export default Logo;
