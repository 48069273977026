import { IconButton } from '@chakra-ui/button';
import React from 'react';
import { FaTwitter } from 'react-icons/fa';

const Twitter = () => {
  return (
    <IconButton
      aria-label=''
      ml={{ base: 1, md: 2 }}
      icon={<FaTwitter />}
      isRound={true}
      alignSelf={'center'}
      onClick={() => window.open('https://twitter.com/iamabhiramhari')}
    ></IconButton>
  );
};

export default Twitter;
