import { IconButton } from '@chakra-ui/button';
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const Whatsapp = () => {
  return (
    <IconButton
      aria-label=''
      ml={{ base: 1, md: 2 }}
      icon={<FaWhatsapp />}
      isRound={true}
      alignSelf={'center'}
      onClick={() =>
        window.open('https://api.whatsapp.com/send?phone=918301049118')
      }
    ></IconButton>
  );
};

export default Whatsapp;
