import { Flex, IconButton, Spacer, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import InstagramIcon from './icons/Instagram';
import Logo from './icons/Logo';
import WhatsappIcon from './icons/Whatsapp';

const TopNavbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === 'dark';

  return (
    <Flex w='100%' p={{ base: 2, md: 5 }}>
      <Logo />
      <Spacer />

      {/* <TwitterIcon /> */}
      <InstagramIcon />
      <WhatsappIcon />

      <IconButton
        aria-label=''
        ml={{ base: 4, md: 8 }}
        icon={isDark ? <FaSun /> : <FaMoon />}
        isRound={true}
        alignSelf={'center'}
        onClick={toggleColorMode}
      ></IconButton>
    </Flex>
  );
};

export default TopNavbar;
